@import '../../node_modules/svbstrate/src/lib/reset.css';
@import '../../node_modules/svbstrate/src/lib/display.css';
@import '../../node_modules/svbstrate/src/lib/positioning.css';
@import '../../node_modules/svbstrate/src/lib/flexbox.css';
@import '../../node_modules/svbstrate/src/lib/align.css';
@import '../../node_modules/svbstrate/src/lib/spacing.css';
/* @import '../../node_modules/svbstrate/src/lib/forms.css'; */
@import '../../node_modules/svbstrate/src/lib/z-index.css';

@import './lib/_var.css';
@import './lib/_colors.css';
@import './lib/_typography.css';
@import './lib/_containers.css';
@import './lib/_header.css';
@import './lib/_image.css';
@import './lib/_button.css';

@import './site/_auth.css';
@import './site/_film.css';

body, html {
  background-color: black;
  color: white;
}

.alpha {
  li {
    list-style-type: lower-alpha;
  }
}

a {
  opacity: 0.8;
}

li {
  padding: 0.5rem 0;
  opacity: 0.8;
}