.button {
  transition: all 0.3s ease-in-out;
  border: 1px solid rgba(255,255,255,0.2);
  background-color: transparent; 
  color: white;
  padding: 1rem 2rem;
  cursor: pointer;
}

.button:hover {
  background-color: white;
  color: black;
}