.image__block {
  transition: all 0.3s ease-in-out;
  opacity: 0;
}

.image__block img {
  transition: all 0.3s ease-in-out;
  transition-delay: 0s;
  opacity: 0;
  object-fit: cover;
  object-position: center center;
}

.image__block img.is-visible {
  transform: translateY(0px);
  opacity: 1;
}

.image__block.is-visible {
  opacity: 1;
  transform: translateY(0px);
}

.image__block.background {
  background-color: black;
}

.is-visible img {
  transform: translateY(0px);
}

 .gatsby-image-wrapper {
   width: 100%;
   height: 100%;
 }