.films__header {
  border-bottom: 1px solid rgba(255,255,255, 0.4);
}

.films__content {
  display: grid;
  grid-template-rows: 1fr;
  grid-auto-rows: 1fr;
}

.films__row {
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  background-color: rgba(255,255,255, 0.05);
  border-bottom: 1px solid rgba(255,255,255, 0.4);

  &-date {
    span {
      display: block;
      text-align: right
    }
  }
}

.films__row:hover {
  background-color: rgba(255,255,255, 0.2);
  svg {
    transform: translateX(5px);
  }
}

.films__row  svg {
  transition: all 0.3s ease-in-out;
  width: 46px;
}

.film {
  &__wrapper {
    @media (--800) {
      display: flex;
      flex-direction: column;
    }
  }
  &__link {
    border-bottom: 1px solid rgba(0,0,0,0);
    &:hover {
      border-bottom: 1px solid white;
    }
    .film__clips & {
      margin-top: 5px;
    }
  }
  &__single {
    opacity: 0;
    transition: all 0.3s ease-in-out;
    &.loaded {
      opacity: 1;
    }
  }
  &__back {
    .rotate {
      transform: rotate(180deg);
    }
    svg {
      width: 50px;
      height: auto;
    }
    h5 {
      background-color: black;
      transition: all 0.3s ease-in-out;
    }
    &:hover {
      h5 {
        transform: translateX(-20px);
      }
    }
  }
  &__poster {
    &:hover {
      .film__images-single-icon {
        opacity: 1;
      }
    }
  }
  &__video-preview {
    &:hover {
      .image__block {
        opacity: 0.5;
      }
      .film__images-single-icon {
        opacity: 1;
      }
    }
  }
  &__content {
    padding-top: 2rem;
    &-inner {
      padding: 0 1rem;
      width: 70%;

      @media (--800) {
        width: 100%;
        padding: 1rem 0 0;
      }
    }
    &-single {
      width: calc((1/3) * 100%);
      padding-left: 20px;

      @media (--800) {
        width: calc(50% - 5px);
        padding: 0;
      }

      &-inner {
        position: relative;
        padding-top: calc((88/166) * 100%);
      }
      &--clip {
        margin-top: 1rem;
      }
    }
    &-downloads {
      position: relative;
      margin-top: 1rem;
      padding-top: 1rem;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: calc(100% - 20px);
        height: 1px;
        background: rgba(255,255,255,0.3);
      }

      @media (--800) {
        &::before {
          width: 100%;
        }
      }
    }
    &-dates {
      margin-top: 1rem;
      padding-top: 1rem;
      border-top: 1px solid rgba(255,255,255,0.3);
    }
    &-date {
      margin-top: 0;
      span {
        display: block;
      }
      &--preview {
        opacity: 1;
        color: var(--white);
      }
      &--release {
        text-transform: capitalize;
      }
    }
  }

  &__images {
    margin-top: 30px;
    max-width: 700px;
    border-top: 0;
    position: relative;

    &-single {
      width: 100%;
      
      &:hover {
        .image__block {
          opacity: 0.5;
        }
        .film__images-single-icon {
          opacity: 1;
        }
      }
      &-icon {
        transition: all 0.3s ease-in-out;
        opacity: 0;
        width: 14px;
        height: 14px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .download-link {
        float: right;
        padding-top: 10px;
        opacity: 0;
        transition: opacity 0.5s linear;

        svg {
          max-height: 14px;
          max-width: 14px;
        }

        a > * {
          display: inline-block;
        }
      }
    }

    &-container {
      &--slide {
        .film__images-single {
          width: 100%;
          
          &.swiper-slide {
            max-height: 507px;

            .slide__inner {
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
            }

            .image__block {
              height: 100%;

              img {
                object-fit: contain;
                object-position: left;
                flex: 1;
                max-height: 469px;
              }
            }

            &-active {

              .download-link {
                opacity: 1;
              }
            }
          }

          &:hover .image__block {
            opacity: 1;
          }
        }

        .swiper-button-next,
        .swiper-button-prev {
          opacity: 0;
          transition: opacity 0.2s ease-out;
          height: 100%;

          &:hover {
            opacity: 1;
          }
        }

        .swiper-button-next {

          &::after {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            width: 20px;
            height: 30px;
            background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 10 17' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.4l8 7-7.8 7' stroke='%23fff' stroke-width='1.5' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            transform: translate(-50%, -50%);

            @media (--800) {
              width: 10px;
              height: 15px;
            }
          }
        }

        .swiper-button-prev {
          &::after {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            width: 20px;
            height: 30px;
            background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 10 17' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 15.6l-8-7 7.8-7' stroke='%23fff' stroke-width='1.5' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            transform: translate(-50%, -50%);

            @media (--800) {
              width: 10px;
              height: 15px;
            }
          }
        }
      }
    }
  }
  &__poster {
    max-width: 30%;
    
    @media (--800) {
      max-width: 100%;
    }
  }
  &__preview {
    min-width: 50vw;
    width: 100%;
    max-width: 900px;
  }
  &__images-slide {
    width: 100%;
  }
  &__video-preview {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &__clips,
  &__download {
    margin-left: -20px;

    @media (--800) {
      margin-left: 0;
    }
  }

  &__clips {
    justify-content: flex-start;
    padding-top: 0;

    @media (--800) {
      justify-content: space-between;
    }
  }
  
  &__icon-download {
    min-width: 14px;
    min-height: 14px;
    margin-top: 3px;
  }
}

.swiper-button-next,
.swiper-button-prev {
  width: 90px;
  height: calc(100% - 28px) !important;
  position: absolute;
  top: 0;
  cursor: pointer;
  z-index: 4;

  &:focus {
    outline: none;
  }
  
  @media (--400) {
    width: 40px;
  }

  &.swiper-button-disabled {
    display: none;
  }
}

.swiper-button-next {
  right: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
}

.swiper-button-prev {
  left: 0;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
}

.swiper-container-thumbs {

  .swiper-button-next,
  .swiper-button-prev {
    background: none;
  }

  .swiper-slide {
    opacity: 0.5;
    max-height: 86px;

    @media (--800) {
      max-height: 60px;
    }

    &-thumb-active {
      opacity: 1;
    }
  }
}

.swiper-container {
  position: relative;
  overflow: hidden;
  padding: 0;
  z-index: 1;
  width: 100%;

  & + .swiper-container {
    margin-top: 2rem;
  }
}

.swiper-wrapper {
  display: flex;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  color: #fff;
  font-size: 1.5rem;
}

.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(0,0,0,0.5);
  &__video {
    width: 100%;
    max-width: 900px;
    padding: 2rem;
  }
  &__close {
    color: black;
    background: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    border: none;
    font-size: 30px;
  }
}

.modal.is-open {
  display: flex;
}



.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
  background: white !important;
}

.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
  background: white!important;
}
.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
  background: white!important;
}

.plyr__control--overlaid {
  background: white !important;
}

.plyr--full-ui input[type=range] {
  color: white !important;
}
.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
  color: black !important;
}

.plyr__control--overlaid {
  color: black !important;
}

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.67) !important;
}
