html, body{ margin:0 }
*,
*::before,
*::after{
  box-sizing:border-box;
}
a,
button,
[role="button"],
input,
label,
select,
textarea{
  touch-action:manipulation;
}
.block{ display:block }
.inline-block{ display:inline-block }
.rel{ position:relative }
.abs{ position:absolute }
.fix{ position:fixed }
.fill, .top{ top:0 }
.fill, .bottom{ bottom:0 }
.fill, .left{ left:0 }
.fill, .right{ right:0 }
.x{ width:100% }
.y{ height:100% }
.f{ display:flex }
.fw{ flex-wrap:wrap }
.ais{ align-items:flex-start }
.aie{ align-items:flex-end }
.aic{ align-items:center }
.aib{ align-items:baseline }
.jcs{ justify-content:flex-start }
.jce{ justify-content:flex-end }
.jcc{ justify-content:center }
.jcb{ justify-content:space-between }
.fa{
  flex:1 1 auto;
  min-width:0;
  min-height:0;
}
.al{ text-align:left }
.ac{ text-align:center }
.ar{ text-align:right }
.aj{ text-align:justify }
.mxa, .ma{ margin-left:auto }
.mxa, .ma{ margin-right:auto }
.mya, .ma{ margin-top:auto }
.mya, .ma{ margin-bottom:auto; }
.pt0, .py0, .p0{ padding-top:0 }
.pb0, .py0, .p0{ padding-bottom:0 }
.mt0, .my0, .m0{ margin-top:0 }
.mb0, .my0, .m0{ margin-bottom:0 }
.mt025, .my025, .m025{ margin-top:0.25em }
.mb025, .my025, .m025{ margin-bottom:0.25em }
.ml025, .mx025, .m025{ margin-left:0.25em }
.mr025, .mx025, .m025{ margin-right:0.25em }
.pt025, .py025, .p025{ padding-top:0.25em }
.pb025, .py025, .p025{ padding-bottom:0.25em }
.pl025, .px025, .p025{ padding-left:0.25em }
.pr025, .px025, .p025{ padding-right:0.25em }
.mt05, .my05, .m05{ margin-top:0.5em }
.mb05, .my05, .m05{ margin-bottom:0.5em }
.ml05, .mx05, .m05{ margin-left:0.5em }
.mr05, .mx05, .m05{ margin-right:0.5em }
.pt05, .py05, .p05{ padding-top:0.5em }
.pb05, .py05, .p05{ padding-bottom:0.5em }
.pl05, .px05, .p05{ padding-left:0.5em }
.pr05, .px05, .p05{ padding-right:0.5em }
.mt075, .my075, .m075{ margin-top:0.75em }
.mb075, .my075, .m075{ margin-bottom:0.75em }
.ml075, .mx075, .m075{ margin-left:0.75em }
.mr075, .mx075, .m075{ margin-right:0.75em }
.pt075, .py075, .p075{ padding-top:0.75em }
.pb075, .py075, .p075{ padding-bottom:0.75em }
.pl075, .px075, .p075{ padding-left:0.75em }
.pr075, .px075, .p075{ padding-right:0.75em }
.mt1, .my1, .m1{ margin-top:1em }
.mb1, .my1, .m1{ margin-bottom:1em }
.ml1, .mx1, .m1{ margin-left:1em }
.mr1, .mx1, .m1{ margin-right:1em }
.pt1, .py1, .p1{ padding-top:1em }
.pb1, .py1, .p1{ padding-bottom:1em }
.pl1, .px1, .p1{ padding-left:1em }
.pr1, .px1, .p1{ padding-right:1em }
.z0{ z-index:0 }
.z1{ z-index:100 }
.z2{ z-index:200 }
.z3{ z-index:300 }
.z4{ z-index:400 }
.z5{ z-index:500 }
.z6{ z-index:600 }
.z7{ z-index:700 }
.z8{ z-index:800 }
.z9{ z-index:900 }
.z10{ z-index:1000 }
:root{
  --200:200ms;
  --400:400ms;
  --600:600ms;
  --800:800ms;
  --1000:1000ms;
  --ease:ease-in-out;
  --cubic:cubic-bezier(.12,.67,.53,1);
}
:root{
  --black:#000;
  --white:#FFF;
  --gray:#EAEAEA;
  --theme:#FFF;
}
.cw{
  color:var(--white);
}
.ct{
  color:var(--theme);
}
.bcw{
  background-color:var(--white);
}
.bcgm{
  background-color:var(--gray);
}
.bct{
  background-color:var(--theme);
}
body, .akz{
  font-family:'akzidenz-grotesk', 'sans-serif';
}
.border{
  border:1px solid currentColor;
}
.regular{
  font-weight:normal;
}
.akz-e{
  font-family:'akzidenz-grotesk-extended', sans-serif;
  font-weight:300;
  font-style:normal;
}
html, body{
  font-size:14px;
}
.ls{
  letter-spacing:0.2rem;
}
.ls-s{
  letter-spacing:0.1rem;
}
a{
  transition:all .3s ease-in-out;
  text-decoration:none;
  color:currentColor;
}
a.link__square:hover,
    a.link__square.active{
      border-color:currentColor;
    }
a:hover{
    opacity:0.6;
  }
.hover{
  cursor:pointer;
}
.caps{
  text-transform:uppercase;
}
.underline{
  text-decoration:underline;
}
.x-bold{
  font-weight:800;
}
.heavy{
  font-weight:800;
}
.black{
  font-weight:900;
}
.semi{
  font-weight:600;
}
.no-underline{
  text-decoration:none;
}
.underline-extra{
  margin-bottom:1rem;
}
.underline-extra:after{
    transition:all 0.2s ease-in-out;
    position:absolute;
    content:'';
    bottom:-6px;
    height:1px;
    left:0;
    width:100%;
    background-color:currentColor;
  }
.underline-extra:hover:after{
      transform:translateY(-3px);
    }
p, .p, .article ul, .article ol, .standard__text li,
.article h2, .article h3, p span{
  opacity:0.7;
}
.terms__single ul, .terms__single ol{
    opacity:0.7;
    margin-left:30px;
  }
.terms__single li{
    list-style-type:disc;
  }
li{
  font-size:1.1rem;
}
@media (max-width: 37.5em){
li{
    font-size:18px
}
  }
h1, h2, h3, h4, h5, h6{
  font-style:normal;
  font-weight:300;
}
h1 strong, h2 strong, h3 strong, h4 strong, h5 strong, h6 strong{
    font-weight:300;
  }
h1, .h1{
  font-size:2.57143rem;
  line-height:1.2;
  font-weight:normal;
}
@media (max-width: 37.5em){
h1, .h1{
    font-size:3rem
}
  }
@media (max-width: 50em){
h1, .h1{
    font-size:3.5rem
}
  }
@media (max-width: 62.5em){
h1, .h1{
    font-size:4rem
}
    h1.h1-display, .h1.h1-display{
      line-height:1.2;
      font-size:4.75rem;
    }
  }
h2, .h2{
  font-size:1.7rem;
  line-height:1.4;
  font-weight:normal;
}
@media (max-width: 50em){
h2, .h2{
    font-size:2.57143rem
}
  }
@media (max-width: 62.5em){
h2, .h2{
    font-size:2.85714rem
}
  }
h2 strong, .h2 strong{
    font-weight:normal;
  }
h2.level-question, .h2.level-question{
    font-size:16px !important;
  }
@media (max-width: 50em){
h2.level-question, .h2.level-question{
      font-size:18px !important
  }
    }
h3, .h3{
  font-size:1.5rem;
  line-height:1.3;
  font-weight:normal;
}
@media (max-width: 37.5em){
h3, .h3{
    font-size:2rem;
    padding-top:1rem
}
  }
@media (max-width: 50em){
h3, .h3{
    font-size:2.3rem;
    line-height:1.2
}
  }
@media (max-width: 62.5em){
h3, .h3{
    line-height:1.4;
    font-size:2.375rem
}
  }
h4, .h4{
  font-size:1.4rem;
  line-height:1.3;
  font-weight:normal;
}
@media (max-width: 37.5em){
h4, .h4{
    font-size:1.4375rem
}
  }
@media (max-width: 62.5em){
h4, .h4{
    font-size:1.85714rem
}
  }
h5, .h5{
  letter-spacing:0.05rem;
  line-height:1.4;
  font-weight:normal;
  font-size:0.9rem;
}
@media (max-width: 37.5em){
h5, .h5{
    line-height:1.5;
    font-size:1.1rem
}
  }
h6{
  font-weight:normal;
  font-size:20px;
  letter-spacing:0.07rem;
  margin-right:6px;
}
@media (max-width: 50em){
h6{
    font-size:26px
}
  }
h2.level-6{
   font-weight:300;
   text-transform:uppercase;
   font-style:normal;
   font-size:16px;
 }
.tracking{
  letter-spacing:0.005rem;
}
.anim.no-delay,
.show.no-delay.up{
  transition-delay:0s !important;
}
.small{
  font-size:12px;
  line-height:1.5;
}
.s14, .s14 p{
  font-size:12px;
  word-break:keep-all;
}
@media (max-width: 37.5em){
.s14, .s14 p{
    font-size:14px
}
  }
.s16{
  font-size:14px;
}
@media (max-width: 37.5em){
.s16{
    font-size:16px
}
  }
.s18{
  font-size:16px;
}
@media (max-width: 50em){
.s18{
    font-size:18px
}
  }
.s22{
  font-size:18px;
}
@media (max-width: 50em){
.s22{
    font-size:22px
}
  }
.container{
  max-width:1000px;
  margin-left:auto;
  margin-right:auto;
}
.container--xs{
  max-width:500px;
}
.container--s{
  max-width:740px;
}
.container--m{
  max-width:1000px;
}
.container--l{
  max-width:1200px;
}
.container--xl{
  max-width:1600px;
}
.p15{
  padding:1.5rem;
}
.py15{ padding-top:1.5rem; }
.py15{ padding-bottom:1.5rem; }
.outer{
  padding:3rem 1.5em;
}
@media (min-width: 600px){
  .outer{
    padding:3rem 3em;
  }
}
.header__logo{
  max-width:80px;
  height:auto;
}
.image__block{
  transition:all 0.3s ease-in-out;
  opacity:0;
}
.image__block img{
  transition:all 0.3s ease-in-out;
  transition-delay:0s;
  opacity:0;
  object-fit:cover;
  object-position:center center;
}
.image__block img.is-visible{
  transform:translateY(0px);
  opacity:1;
}
.image__block.is-visible{
  opacity:1;
  transform:translateY(0px);
}
.image__block.background{
  background-color:black;
}
.is-visible img{
  transform:translateY(0px);
}
.gatsby-image-wrapper{
   width:100%;
   height:100%;
 }
.button{
  transition:all 0.3s ease-in-out;
  border:1px solid rgba(255, 255, 255, .2);
  background-color:transparent; 
  color:white;
  padding:1rem 2rem;
  cursor:pointer;
}
.button:hover{
  background-color:white;
  color:black;
}
.auth__none{
    min-height:70vh;

  }
.auth__input{
    max-width:400px;
    font-family:'akzidenz-grotesk', 'sans-serif';
    font-size:1.2rem;
    border:1px solid rgba(255, 255, 255, .2);
    background-color:black;
    color:white;
  }
.auth__input-checkbox{
      position:absolute;
      left:0;
      top:0;
      width:100%;
      height:100%;
      opacity:0;
      margin:0;
      cursor:pointer;
    }
.auth__input-checkbox:checked + .auth__checkmark::after{
          display:block;
        }
.auth__group{
    max-width:400px;
  }
.auth__three{
    width:calc(33% - 0.25rem);
  }
.auth__label{
    color:currentColor;
    opacity:0.8;
  }
.auth__label--checkbox{
      display:inline-block;
      position:relative;
      padding-left:30px;
      -webkit-user-select:none;
         -moz-user-select:none;
              user-select:none;
      cursor:pointer;
    }
.auth__checkmark{
    position:absolute;
    top:0;
    left:0;
    width:20px;
    height:20px;
    background:rgba(255, 255, 255, .8);
  }
.auth__checkmark:after{
      content:'';
      position:absolute;
      display:none;
      left:7px;
      top:3px;
      width:7px;
      height:12px;
      border:solid var(--black);
      border-width:0 2px 2px 0;
      transform:rotate(45deg);
      pointer-events:none;
    }
select{
  padding:1rem;
  height:40px;
}
.films__header{
  border-bottom:1px solid rgba(255, 255, 255, .4);
}
.films__content{
  display:grid;
  grid-template-rows:1fr;
  grid-auto-rows:1fr;
}
.films__row{
  transition:all 0.3s ease-in-out;
  cursor:pointer;
  background-color:rgba(255, 255, 255, .05);
  border-bottom:1px solid rgba(255, 255, 255, .4);
}
.films__row-date span{
      display:block;
      text-align:right
    }
.films__row:hover{
  background-color:rgba(255, 255, 255, .2);
}
.films__row:hover svg{
    transform:translateX(5px);
  }
.films__row  svg{
  transition:all 0.3s ease-in-out;
  width:46px;
}
@media (max-width: 50em){
.film__wrapper{
      display:flex;
      flex-direction:column
  }
    }
.film__link{
    border-bottom:1px solid rgba(0, 0, 0, 0);
  }
.film__link:hover{
      border-bottom:1px solid white;
    }
.film__clips .film__link{
      margin-top:5px;
    }
.film__single{
    opacity:0;
    transition:all 0.3s ease-in-out;
  }
.film__single.loaded{
      opacity:1;
    }
.film__back .rotate{
      transform:rotate(180deg);
    }
.film__back svg{
      width:50px;
      height:auto;
    }
.film__back h5{
      background-color:black;
      transition:all 0.3s ease-in-out;
    }
.film__back:hover h5{
        transform:translateX(-20px);
      }
.film__poster:hover .film__images-single-icon{
        opacity:1;
      }
.film__video-preview:hover .image__block{
        opacity:0.5;
      }
.film__video-preview:hover .film__images-single-icon{
        opacity:1;
      }
.film__content{
    padding-top:2rem;
  }
.film__content-inner{
      padding:0 1rem;
      width:70%;
    }
@media (max-width: 50em){
.film__content-inner{
        width:100%;
        padding:1rem 0 0
    }
      }
.film__content-single{
      width:33.33333%;
      padding-left:20px;
    }
@media (max-width: 50em){
.film__content-single{
        width:calc(50% - 5px);
        padding:0
    }
      }
.film__content-single-inner{
        position:relative;
        padding-top:53.01205%;
      }
.film__content-single--clip{
        margin-top:1rem;
      }
.film__content-downloads{
      position:relative;
      margin-top:1rem;
      padding-top:1rem;
    }
.film__content-downloads::before{
        content:'';
        position:absolute;
        top:0;
        right:0;
        width:calc(100% - 20px);
        height:1px;
        background:rgba(255, 255, 255, .3);
      }
@media (max-width: 50em){
        .film__content-downloads::before{
          width:100%;
        }
      }
.film__content-dates{
      margin-top:1rem;
      padding-top:1rem;
      border-top:1px solid rgba(255, 255, 255, .3);
    }
.film__content-date{
      margin-top:0;
    }
.film__content-date span{
        display:block;
      }
.film__content-date--preview{
        opacity:1;
        color:var(--white);
      }
.film__content-date--release{
        text-transform:capitalize;
      }
.film__images{
    margin-top:30px;
    max-width:700px;
    border-top:0;
    position:relative;
  }
.film__images-single{
      width:100%;
    }
.film__images-single:hover .image__block{
          opacity:0.5;
        }
.film__images-single:hover .film__images-single-icon{
          opacity:1;
        }
.film__images-single-icon{
        transition:all 0.3s ease-in-out;
        opacity:0;
        width:14px;
        height:14px;
        left:50%;
        top:50%;
        transform:translate(-50%, -50%);
      }
.film__images-single .download-link{
        float:right;
        padding-top:10px;
        opacity:0;
        transition:opacity 0.5s linear;
      }
.film__images-single .download-link svg{
          max-height:14px;
          max-width:14px;
        }
.film__images-single .download-link a > *{
          display:inline-block;
        }
.film__images-container--slide .film__images-single{
          width:100%;
        }
.film__images-container--slide .film__images-single.swiper-slide{
            max-height:507px;
          }
.film__images-container--slide .film__images-single.swiper-slide .slide__inner{
              height:100%;
              display:flex;
              flex-direction:column;
              align-items:flex-start;
            }
.film__images-container--slide .film__images-single.swiper-slide .image__block{
              height:100%;
            }
.film__images-container--slide .film__images-single.swiper-slide .image__block img{
                object-fit:contain;
                object-position:left;
                flex:1;
                max-height:469px;
              }
.film__images-container--slide .film__images-single.swiper-slide-active .download-link{
                opacity:1;
              }
.film__images-container--slide .film__images-single:hover .image__block{
            opacity:1;
          }
.film__images-container--slide .swiper-button-next,
        .film__images-container--slide .swiper-button-prev{
          opacity:0;
          transition:opacity 0.2s ease-out;
          height:100%;
        }
.film__images-container--slide .swiper-button-next:hover, .film__images-container--slide .swiper-button-prev:hover{
            opacity:1;
          }
.film__images-container--slide .swiper-button-next::after{
            content:'';
            position:absolute;
            left:50%;
            top:50%;
            width:20px;
            height:30px;
            background-image:url("data:image/svg+xml,%3Csvg viewBox='0 0 10 17' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.4l8 7-7.8 7' stroke='%23fff' stroke-width='1.5' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            background-repeat:no-repeat;
            transform:translate(-50%, -50%);
          }
@media (max-width: 50em){
.film__images-container--slide .swiper-button-next::after{
              width:10px;
              height:15px
          }
            }
.film__images-container--slide .swiper-button-prev::after{
            content:'';
            position:absolute;
            left:50%;
            top:50%;
            width:20px;
            height:30px;
            background-image:url("data:image/svg+xml,%3Csvg viewBox='0 0 10 17' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 15.6l-8-7 7.8-7' stroke='%23fff' stroke-width='1.5' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            background-repeat:no-repeat;
            transform:translate(-50%, -50%);
          }
@media (max-width: 50em){
.film__images-container--slide .swiper-button-prev::after{
              width:10px;
              height:15px
          }
            }
.film__poster{
    max-width:30%;
  }
@media (max-width: 50em){
.film__poster{
      max-width:100%
  }
    }
.film__preview{
    min-width:50vw;
    width:100%;
    max-width:900px;
  }
.film__images-slide{
    width:100%;
  }
.film__video-preview{
    position:absolute;
    left:0;
    right:0;
    bottom:0;
    top:0;
    width:100%;
    height:100%;
  }
.film__clips,
  .film__download{
    margin-left:-20px;
  }
@media (max-width: 50em){
.film__clips,
  .film__download{
      margin-left:0
  }
    }
.film__clips{
    justify-content:flex-start;
    padding-top:0;
  }
@media (max-width: 50em){
.film__clips{
      justify-content:space-between
  }
    }
.film__icon-download{
    min-width:14px;
    min-height:14px;
    margin-top:3px;
  }
.swiper-button-next,
.swiper-button-prev{
  width:90px;
  height:calc(100% - 28px) !important;
  position:absolute;
  top:0;
  cursor:pointer;
  z-index:4;
}
.swiper-button-next:focus, .swiper-button-prev:focus{
    outline:none;
  }
@media (max-width: 25em){
.swiper-button-next,
.swiper-button-prev{
    width:40px
}
  }
.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled{
    display:none;
  }
.swiper-button-next{
  right:0;
  background:linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .8) 100%);
}
.swiper-button-prev{
  left:0;
  background:linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .8) 100%);
}
.swiper-container-thumbs .swiper-button-next,
  .swiper-container-thumbs .swiper-button-prev{
    background:none;
  }
.swiper-container-thumbs .swiper-slide{
    opacity:0.5;
    max-height:86px;
  }
@media (max-width: 50em){
.swiper-container-thumbs .swiper-slide{
      max-height:60px
  }
    }
.swiper-container-thumbs .swiper-slide-thumb-active{
      opacity:1;
    }
.swiper-container{
  position:relative;
  overflow:hidden;
  padding:0;
  z-index:1;
  width:100%;
}
.swiper-container + .swiper-container{
    margin-top:2rem;
  }
.swiper-wrapper{
  display:flex;
}
.swiper-slide{
  display:flex;
  align-items:center;
  justify-content:center;
  flex-shrink:0;
  color:#fff;
  font-size:1.5rem;
}
.modal{
  display:none;
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index:100;
  background-color:rgba(0, 0, 0, .5);
}
.modal__video{
    width:100%;
    max-width:900px;
    padding:2rem;
  }
.modal__close{
    color:black;
    background:white;
    border-radius:50%;
    width:40px;
    height:40px;
    border:none;
    font-size:30px;
  }
.modal.is-open{
  display:flex;
}
.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true]{
  background:white !important;
}
.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true]{
  background:white!important;
}
.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true]{
  background:white!important;
}
.plyr__control--overlaid{
  background:white !important;
}
.plyr--full-ui input[type=range]{
  color:white !important;
}
.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true]{
  color:black !important;
}
.plyr__control--overlaid{
  color:black !important;
}
.plyr__control.plyr__tab-focus{
  box-shadow:0 0 0 5px rgba(0, 0, 0, .67) !important;
}
body, html{
  background-color:black;
  color:white;
}
.alpha li{
    list-style-type:lower-alpha;
  }
a{
  opacity:0.8;
}
li{
  padding:0.5rem 0;
  opacity:0.8;
}