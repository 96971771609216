body, .akz {
  font-family: 'akzidenz-grotesk', 'sans-serif';
}


.border {
  border: 1px solid currentColor;
}

.regular {
  font-weight: normal;
}

.akz-e {
  font-family: 'akzidenz-grotesk-extended', sans-serif;
  font-weight: 300;
  font-style: normal;
}

html, body {
  font-size: 14px;
}

.ls {
  letter-spacing: 0.2rem;
}

.ls-s {
  letter-spacing: 0.1rem;
}

a {
  transition: all .3s ease-in-out;
  text-decoration: none;
  color: currentColor;
  &.link__square {
    &:hover,
    &.active {
      border-color: currentColor;
    }
  }
  &:hover {
    opacity: 0.6;
  }
}

.hover {
  cursor: pointer;
}

.caps {
  text-transform: uppercase;
}

.underline {
  text-decoration: underline;
}

.x-bold {
  font-weight: 800;
}

.heavy {
  font-weight: 800;
}

.black {
  font-weight: 900;
}

/* .bold {
  font-weight: 600;
} */

.semi {
  font-weight: 600;
}

.no-underline {
  text-decoration: none;
}

.underline-extra {
  margin-bottom: 1rem;
  &:after {
    transition: all 0.2s ease-in-out;
    position: absolute;
    content: '';
    bottom: -6px;
    height: 1px;
    left: 0;
    width: calc(100%);
    background-color: currentColor;
  }
  &:hover {
    &:after {
      transform: translateY(-3px);
    }
  }
}

p, .p, .article ul, .article ol, .standard__text li,
.article h2, .article h3, p span {
  opacity: 0.7;
}

.terms__single {
  ul, ol {
    opacity: 0.7;
    margin-left: 30px;
  }
  li {
    list-style-type: disc;
  }
}

li {
  font-size: 1.1rem;
  @media (--600) {
    font-size: 18px;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-style: normal;
  font-weight: 300;
  strong {
    font-weight: 300;
  }
}

h1, .h1 {
  font-size: calc((36 /14) * 1rem);
  line-height: 1.2;
  font-weight: normal;
  @media (--600) {
    font-size: calc((48 / 16) * 1rem);
  }
  @media (--800) {
    font-size: calc((56 / 16) * 1rem);
  }
  @media (--1000) {
    font-size: calc((64 / 16) * 1rem);
    &.h1-display {
      line-height: 1.2;
      font-size: calc((76 /16) * 1rem);
    }
  }
}

h2, .h2 {
  font-size: 1.7rem;
  line-height: 1.4;
  font-weight: normal;
  @media (--800) {
    font-size: calc((36 / 14) * 1rem);
  }
  @media (--1000) {
    font-size: calc((40 / 14) * 1rem);
  }
  strong {
    font-weight: normal;
  }
  &.level-question {
    font-size: 16px !important;
    @media (--800) {
      font-size: 18px !important;
    }
  }
}

h3, .h3 {
  font-size: 1.5rem;
  line-height: 1.3;
  font-weight: normal;

  @media (--600) {
    font-size: 2rem;
    padding-top: 1rem;
  }
  @media (--800) {
    font-size: 2.3rem;
    /* width: 80%; */
    line-height: 1.2;
  }
  @media (--1000) {
    /* padding-top: 20px;
    padding-bottom: 20px; */
    line-height: 1.4;
    font-size: calc((38 / 16) * 1rem);
  }
}

h4, .h4 {
  font-size: 1.4rem;
  line-height: 1.3;
  font-weight: normal;
  @media (--600) {
    font-size: calc((23 /16) * 1rem);
  }
  @media (--1000) {
    font-size: calc((26 /14) * 1rem);
  }
}

h5, .h5 {
  letter-spacing: 0.05rem;
  line-height: 1.4;
  font-weight: normal;
  font-size: 0.9rem;
  @media (--600) {
    /* padding-top: 1.5rem; */
    line-height: 1.5;
    font-size: 1.1rem;
  }
}
h6 {
  font-weight: normal;
  font-size: 20px;
  letter-spacing: 0.07rem;
  margin-right: 6px;
  @media (--800) {
    font-size: 26px;
  }
}
 h2.level-6 {
   font-weight: 300;
   text-transform: uppercase;
   font-style: normal;
   font-size: 16px;
 }
.tracking {
  letter-spacing: 0.005rem;
}

.anim.no-delay,
.show.no-delay.up {
  transition-delay: 0s !important;
}

.small {
  font-size: 12px;
  line-height: 1.5;
}

.s14, .s14 p {
  font-size: 12px;
  word-break: keep-all;
  @media (--600) {
    font-size: 14px;
  }
}

.s16 {
  font-size: 14px;
  @media (--600) {
    font-size: 16px;
  }
}

.s18 {
  font-size: 16px;
  @media (--800) {
    font-size: 18px;
  }
}

.s22 {
  font-size: 18px;
  @media (--800) {
    font-size: 22px;
  }
}
