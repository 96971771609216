:root {
  --black: #000;
  --white: #FFF;
  --gray: #EAEAEA;
  --theme: #FFF;
}

.cw {
  color: var(--white);
}
.ct {
  color: var(--theme);
}
.bcw {
  background-color: var(--white);
}
.bcgm {
  background-color: var(--gray);
}
.bct {
  background-color: var(--theme);
}
