@custom-media --400 (width <= 25em);
@custom-media --600 (width <= 37.5em);
@custom-media --800 (width <= 50em);
@custom-media --1000 (width <= 62.5em);
@custom-media --1200 (width <= 75em);
@custom-media --1400 (width <= 87.5em);

:root {
  --200: 200ms;
  --400: 400ms;
  --600: 600ms;
  --800: 800ms;
  --1000: 1000ms;
  --ease: ease-in-out;
  --cubic: cubic-bezier(.12,.67,.53,1);
}
